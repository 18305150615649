@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");
:root {
  --font-sans: "Inter", sans-serif;

  /* BLACK */
  --color-black-primitive: #323232;

  /* GRAY */
  --color-gray-description: #606774;
  --color-gray-disabled: #949494;
  --color-gray-placeholder: #AFAFAF;

  /* OUTLINE */
  --color-outline-dark: #F3F3F3;
  --color-outline-default: #E4E4E4;
  --color-outline-disabled: #EDEDED;
  --color-outline-light: #FAFAFA;

  /* PRIMARY */
  /* --color-primary-base: #454CB5;
  --color-primary-hover: #32388E;
  --color-primary-muted: #F8F9FD; */

  /* SECONDARY */
  /* --color-secondary-base: #263B69;
  --color-secondary-hover: #2B4478;
  --color-secondary-muted: #EEF3FF; */

  /* INFO */
  --color-info-base: #469CE5;
  --color-info-hover: #1D75C0;
  --color-info-muted: #F2F5FF;

  /* SUCCESS */
  --color-success-base: #63D675;
  --color-success-hover: #33BB48;
  --color-success-muted: #EAFFED;

  /* DANGER */
  --color-danger-base: #DB4343;
  --color-danger-hover: #A60C0C;
  --color-danger-muted: #FFF2F2;

  /* WARNING */
  --color-warning-base: #FFB803;
  --color-warning-hover: #DEB124;
  --color-warning-muted: #FFFCF2;

  /* VIOLET */
  --color-violet-base: #9E28B2;
  --color-violet-hover: #5D1269;
  --color-violet-muted: #F8E8FB;

  /* INDIGO */
  --color-indigo-base: #663BB7;
  --color-indigo-hover: #43247D;
  --color-indigo-muted: #EDE3FF;

  /* OLIVE */
  --color-olive-base: #009688;
  --color-olive-hover: #016057;
  --color-olive-muted: #DCF9F6;

  /* ORANGE */
  --color-orange-base: #FD5722;
  --color-orange-hover: #CE471B;
  --color-orange-muted: #FFE4DB;

  /* ASH */
  --color-ash-base: #5F7D8C;
  --color-ash-hover: #395867;
  --color-ash-muted: #E8F7FF;

  /* BROWN */
  --color-brown-base: #795546;
  --color-brown-hover: #583E33;
  --color-brown-muted: #FFF1EB;
}
